<script lang="ts">
  import { onMount } from "svelte";

  import { fade } from "svelte/transition";
  import Logo from "./ProductLogo.svelte";
  import type { Product } from "$lib/products";
  import Icon from "@brave/leo/src/components/icon/icon.svelte";
  import { getFormattedPrice } from "$lib/utils";
  import UnsupportedSystem from "./UnsupportedSystem.svelte";

  export let product: Product;

  const { name, baseName, displayName, description, features, price, hasFreeVersion, trialDays } = product;

  onMount(() => {
    document.body.classList.add("product-landing");

    return () => {
      document.body.classList.remove("product-landing");
    };
  });
</script>

<main
  class="box relative overflow-hidden rounded-none border-divider-subtle p-0 before:absolute before:left-0 before:top-0 before:z-0 before:block before:h-[299px] before:w-full before:-translate-y-3/4 before:rounded-[720px] before:bg-primary-10 before:blur-[100px] md:max-w-[720px] md:border-t"
  in:fade={{ duration: 600 }}
>
  <section
    class="box overflow-hidden rounded-none border-b border-divider-subtle shadow-none max-sm:p-xl max-sm:pt-2xl"
  >
    <header class="relative z-10 flex items-center justify-center gap-2xl pb-m">
      <Logo {name} />
      <div class="flex flex-col justify-center gap-s">
        <h1 class="text-heading-h2">{baseName}</h1>
        {#if hasFreeVersion}
          <p class="text-large-regular">Premium</p>
        {/if}
      </div>
    </header>
    <div
      class="relative z-10 grid items-center gap-xl p-2xl text-text-secondary sm:grid-cols-2 sm:gap-3xl sm:pb-6xl sm:pt-2xl md:grid-cols-[312px_auto]"
    >
      <p>{description}</p>
      <ul class="flex flex-col gap-s" style="--leo-icon-color: var(--leo-color-primary-40)">
        {#each features as feature}
          <li class="flex gap-m"><Icon name="check-normal" /> {feature}</li>
        {/each}
        <li class="flex gap-m"><Icon name="check-normal" /> Cancel any time</li>
        {#if trialDays > 0}
          <li class="flex gap-m font-semibold text-primary-40">
            <Icon name="check-normal" />
            {trialDays}-day free trial
          </li>
        {/if}
      </ul>
    </div>
    <div class="flex flex-col items-center rounded-m border border-divider-subtle px-xl py-l">
      <div class="text-heading-h2 flex items-center gap-m">
        {getFormattedPrice(price)}
        <span class="text-default-regular text-text-tertiary">a month</span>
      </div>
    </div>
  </section>
  {#if product.browserSupport.isSupported}
    <section class="box flex flex-col gap-2xl rounded-none shadow-none max-sm:p-2xl">
      <slot />
      <p class="text-small-regular text-center text-text-tertiary">
        You're just a step away from {displayName}. Just enter your email address and click Go premium to get started.
        You'll be taken to our payment processing service after login.
      </p>
    </section>
  {:else}
    <section class="md:box rounded-none shadow-none md:p-xl">
      <div class="overflow-hidden border-divider-subtle md:rounded-m md:border">
        <UnsupportedSystem>
          {product.browserSupport.message}
        </UnsupportedSystem>
      </div>
    </section>
  {/if}
</main>
